.ant-select-selection--multiple {
  display: none;
}


.heading {
  text-align: left;
  font-weight: 500;
}

.heading-sm {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}

.ant-select-dropdown-menu-item {
  border-top: 1px solid #ccc;
}

.dataTable {
  margin-top: 17px;
}

.bank-details {
  font-weight: 500;
  text-align: left;
}

.bank-details div {
  width: 40%;
  float: left;
  font-weight: 600;
}


.description{
  text-align: left;
  font-size: 16px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 20px;
}

.description strong {
  font-size: 20px;
  margin-bottom: 20px;
}

.ant-select-dropdown {
  z-index: 1;
}
