
.main--header h2 {
    font-size: 28px;
    color: #fff;
}

.main--header {
    padding: 90px 10px;
    color: #fff;
}

.main--header p {
    font-size: 18px;
}

.main--footer-bottom {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 100px;
}

.main--footer-bottom a.btn.learnMore-btn {
    background: #6558f5;
    color: #fff;
}

.main--footer-bottom p a {
    color: #6558f5;
    margin-left: 10px;
    font-size: 16px;
    border-bottom:1px solid #6558f5;
}

.company-flex {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin: auto;
    justify-content: center;
}

.company-flex img {
    max-width: 185px;
    display: block;
}

.main--footer{
    padding-top: 40px;
}
.main--footer-top h2 {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
}


.ant-modal-footer > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.ant-modal-footer  button {
    border-radius: 20px;
    min-width: 90px;
    margin: 0;
}

.ant-modal-footer {
    border: none;
    padding-top: 0;
}

.ant-modal-header {
    border: none;
    padding-bottom: 0;
}

.ant-modal-body iframe{
    width:100%;
    height: 100%;
    margin-top: 16px;
}

.ant-modal
{
    width: 750px !important;
    transform-origin: 796.5px 330px;
}

@media (min-width: 768px)  {

    .company-flex img:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .company-flex img:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    
.white-card-main{
    position: absolute;
    width: 100%;
     right: 8.33%;
}

    
    
}

@media (max-width: 767px)  {
    
    .company-flex {
        flex-wrap: wrap;
    }

    .main--header h2 {
        font-size: 20px;
        color: #fff;
    }

    .main--header p {
        font-size: 14px;
    }

    .login-container.center-flex{
        display: block;
    }
    .white-card{
        float: none;
        width: 100%;
    }

    .white-card-main {
        display: inline-block;
        width: 100%;
    }

    .main--header {
        padding: 40px 15px;
    }

    .slider-content {
        width: 100%;
    }

    .main--footer-bottom {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        padding-right: 88px;
    }
    a.btn.learnMore-btn {
        margin: 0;
        font-size: 14px !important;
    }

    .main--footer-bottom p a {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
    }

    .main--footer-bottom p a:first-child{
        margin: 0;
    }


    
}





