@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


img { cursor : pointer;}

.app_bg { background-color: #F0F2F5;}
.logo { width: 250px; height: 64px; float: left;text-align: center;}

.color-yellow-opac-35 { background-color :rgba(255, 175, 0,0.35)}
.color-green-opac-35  { background-color :rgba(26, 188, 156,0.35)}
.color-blue-opac-35   { background-color :rgba(13,102,178,0.35)}

.container { background-color: #ffffff; }
/* Clear floats */
.row:after { content: ""; display: table; clear: both;}

.column { float: left; width: 100%;}
.column-20 { float: left; width: 20%;} 
.column-80 { float: left; width: 80%;} 
.column-20 { float: left; width: 20%;}
.column-25 { float: left; width: 25%;}
.column-35 { float: left; width: 35%; position: relative;}
.column-65 { float: left; width: 65%; position: relative;}
.column-75 { float: left; width: 75%;}
.clear-both {clear:both;}

.border-right {border-right : 1px solid #eeeeee !important;}

.header-menu .ant-menu-item, .ant-menu-submenu-title { padding : 0px; margin:  0 20px;}
#OnboardingLayout .header-menu .ant-menu-item, .ant-menu-submenu-title { padding : 0px; margin: 0 0px;}

.table-conatiner { border: 1px solid rgb(0,0,0,0.1);border-radius: 4px;padding : 2px;}

/* Ant Design Component Customization */
.ant-carousel-vertical .slick-dots li { margin-bottom: 15px !important;}
.ant-carousel-vertical .slick-dots li button { width : 12px;height: 12px; border-radius: 6px;}
.ant-carousel-vertical .slick-dots li.slick-active button { width : 16px;height: 16px; border-radius: 8px;margin-left: -3px !important;}
.slick-dots { left : 0;}   
.slick-list { margin-left : 70px !important;} 
/* Tabs */
.airline-details-tabs .ant-tabs-tab { margin-left: 20px !important; margin-right: 0px !important;font-size: 14px;font-weight: 500 !important;font-family: Roboto;cursor: pointer; }
.airline-details-tabs .ant-tabs-tab-active { color : #FFAF00 !important;font-size: 14px;font-weight: 500 !important;font-family: Roboto;}
.airline-details-tabs .ant-tabs-tab-active:nth-child(2n) { color : #1ABC9C !important;font-size: 14px;font-weight: 500 !important;font-family: Roboto;}
.airline-details-tabs .ant-tabs-tab-active:nth-child(3n) { color : #0D66B2 !important;font-size: 14px;font-weight: 500 !important;font-family: Roboto;}

.airline-details-tabs .ant-tabs-ink-bar { background-color: #FFAF00;border-radius: 100px 100px 0 0;height: 4px;}

.airline-details-tabs .ant-tabs-nav .ant-tabs-tab:hover {color :#FFAF00 !important;}
.airline-details-tabs .ant-tabs-nav .ant-tabs-tab:nth-child(2n):hover {color :#1ABC9C !important;}
.airline-details-tabs .ant-tabs-nav .ant-tabs-tab:nth-child(3n):hover {color :#0D66B2 !important;} 

/* Table  */
.ant-table-bordered.ant-table-empty .ant-table-placeholder { border-left : 0px;border-right: 0px;}
.action-search { width: 200px;}
.action-search .ant-input { background-color: rgba(100, 91, 91, 0.1);width: 200px; border-radius: 20px;font-size: 16px;font-family: Roboto;line-height: 24px;}


.airline-logs span { font-size: 11px; line-height: 18px; font-family: Roboto; color: rgba(0, 0, 0, 0.5);}
.airline-logs div { font-size: 14px; line-height: 22px; font-family: Roboto; color: rgba(0, 0, 0, 0.5);}
.airline-comment-box { margin : 15px 0 !important;}

.airline-summary h2 { color: #000000;font-family: Roboto;font-size: 24px;	font-weight: 500;line-height: 36px;margin: 0px;}
.airline-summary .subtitle{ opacity: 0.35;color: #000000;	font-family: Roboto;font-size: 14px;line-height: 18px;margin: 0px;}
.airline-summary .description { margin-top : 20px;opacity: 0.35;color: #000000; font-family: Roboto; text-transform: capitalize;}



.airline-summary .ant-table-thead > tr > th {
    background-color : #F0F2F5;text-align: center; padding: 5px !important;opacity: 0.5;color: #000000;font-family: Roboto;font-size: 12px;font-weight: 500;line-height: 18px;border-right: 1px solid rgb(0,0,0,0.1);
}
.airline-summary .ant-table-tbody > tr > td {
    text-align: center; padding:8px 5px 8px 5px !important;color: #000000;font-family: Roboto;font-size: 12px;font-weight: 500;line-height: 18px;border-right: 1px solid rgb(0,0,0,0.1);border-bottom: 2px solid #F0F2F5;
}
.airline-summary .ant-table-tbody td:nth-last-child(1){ border-right:none;}
.airline-summary .ant-table-thead th:nth-last-child(1){ border-right:none;}
.airline-details-tabs { clear:both;}
.airline-details-tabs .ant-tabs-bar { margin: 0px;border:none;}

.airline-grid .react-grid-Grid { border : none;}
.airline-grid .react-grid-HeaderCell { background-color: #ffffff;color:rgb(0,0,0,0.7);font-family: Roboto;font-size: 12px;font-weight: 600;line-height: 18px;}
.airline-grid .react-grid-Cell { background-color: #ffffff;color: #000000;font-family: Roboto;font-size: 12px;font-weight: normal;line-height: 18px;}
.airline-grid .react-grid-checkbox-label::before {border : 2px solid rgb(0,0,0,0.5);width: 17px; height: 17px;color:#ffffff;}
.airline-grid .react-grid-checkbox:checked+.react-grid-checkbox-label:before {background: #0D66B2;box-shadow: inset 0 0 0 0px #fff;content: "\2713";text-align: center;
    font-size: 14px;border: 2px solid #0D66B2;width: 17px; height: 17px;}
.airline-grid .react-grid-HeaderRow .react-grid-checkbox:checked+.react-grid-checkbox-label:before {content : "\2212"}
.airline-grid .react-grid-Row.row-selected .react-grid-Cell {background-color: #ffffff; }
.airline-grid .react-grid-checkbox-container {line-height: 14px;}
.airline-grid .react-grid-HeaderCell--locked, .airline-grid .react-grid-Cell--locked { border-right: 0px;}

.airline-grid .react-grid-HeaderRow:nth-child(2) .react-grid-HeaderCell { height: 56px;background-color: rgb(13,102,178,0.15);}
.airline-grid .react-grid-HeaderRow:nth-child(2) .react-grid-HeaderCell .form-group {
    border: 1px solid #D9D9D9;border-radius: 4px;background-color: #FFFFFF;
}
.airline-grid .react-grid-HeaderRow:nth-child(2) .react-grid-HeaderCell .form-group input{
    padding: 5px;border: 0px; border-radius: 4px;width: 100%;
}

.grid-col-span {color: #FFAF00;font-family: Roboto;font-size: 12px;line-height: 18px;}

.button-email-logs {  margin-top: 15px; height: 40px;text-align: center; background-color: rgb(13,102,178,0.1); border:none;  }
.button-email-logs span { color : #0D66B2; opacity: 1.0;}
.button-email-logs .anticon-right { padding-top: 3px; float: right; font-size: 15px; color: #0D66B2; font-weight: bold; }

.action-header { padding : 10px; border-bottom: 1px solid rgb(0,0,0,0.1);}
.action-header-right { float : right;}

.button-action { text-align: center; background-color: rgb(13,102,178,0.1); border:none; margin-left: 10px; }
.button-action span { color : #0D66B2; opacity: 1.0; text-transform: uppercase !important;}
.button-action:hover { background-color: #0D66B2; }
.button-action:hover span { color: #ffffff;}

.button-action-active { background-color: #0D66B2; }
.button-action-active span { color: #ffffff; }


.button-action:focus { background-color: #0D66B2}
.button-action:focus span { background-color: #0D66B2; color: #ffffff;}


.action-icon { border:none; margin-left: 10px; align-content: center;cursor: pointer; }

.supplier-top {height: 60px;}
.supplier-top-button-div {float: right;width: 14%;margin-top: 1%;margin-right: 2.5%;}
.supplier-top-button {background-color:#E5EEF6;margin-left: 9px;width: 70px;font-size: 10px;font-weight: inherit;color: #1D79B6;border-color: white;height: 37px}
.supplier-email-button {background-color:white;border-color: #1D79B6 ;margin-left: 3px;font-size: 10px;font-weight: inherit;color: #1D79B6;height: 34px}
.supplier-screen-grid {height: 20;}

.reporting-search-input .ant-input { 
    background-color: rgba(100, 91, 91, 0.1);
    width: 97%;
    height:35px; 
    border-radius: 20px;
    font-size: 12px;
    font-family: Roboto;
    margin-left: 7px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.line-view{
    height: 1.5px;
    background-color: #BBBB;
}

.text-color-gray{
    color:#827a7add;
    font-size: 12px;
}

.checkbox{
    height: 14px;
    margin-top: 20px;
    color: black;
    font-size: 12px;
}

.ant-input-search-icon {
    color: rgba(0, 0, 0, 0.45) !important;
    cursor: pointer !important;
    transition: all .3s !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}

.view-report-button{
    color:#a0088b;
}

.text-color-black{
    color:black;
    font-size: 15px;
}

.list-row-size{
    color:black;
    font-size: 12px;
    margin-top:6px;
    margin-bottom:6px;
}

.header-text{
    color:black;
    font-size: 22px;
    margin-top:15px;
    margin-bottom: 17px;
    text-align: center
}

.document-search {
    width:40% ;
    height:45px;
    font-size: 16px;
    background-color: rgba(100, 91, 91, 0.1);
    border-radius: 20px;
}

.document-margin{
    margin-left:8.33%;
    margin-right:8.33%;
    
}

.booking-text{
    color:#a0088b;
    font-size:18px;
    text-align: center;
}

.booking-text-under{
    font-size:11px;
    text-align: center;
}

.document-card-color{
    background-color: #f0f1f4;
    width:200px;
    border-color: #f0f1f4;
}

.card-image-size{
    width:60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:50px;
}

.card-image-text{
    text-align: center;
}

.booking-card-view{
    width: 200px;
    margin-left:40px;
    height:180px;
}

.content-center{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ant-upload.ant-upload-drag {
    border-style: dashed !important;
    border-color: #2066b2 !important;
    transition: border-color 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 500px;
    position: relative;
    background: #fafafa;
    border-width: 3px;
}

.uploade_booking_text{
    font-size: 15px;
    font-weight: 500;
    color:black;
    margin-left: 15px;
}

.uploade_booking_under_text{
    margin-left: 15px;
    margin-bottom: 20px;
}

.floate_right{
    float: right;
    text-align: right;
    line-height: 1.5em;
}

.text_size_another{
    font-size: 10px;
    text-align: right;
    line-height: 1.5em;
    color: #5f5858aa;
}
 
.uploade_ticket_image{
    width: 40px;
    height: 40px;
    margin-bottom: 15px
}

.uploade_file_image{
    width: 70px;
    height: 80px;
    margin-bottom: 15px;
    margin-top: -50px;
}

/* dashboard */
.dashboard-container{padding: 25px 40px 25px 40px; }
.fontSize-24{font-size: 24px}
.fontSize-18{font-size: 18px}
.button-blue { width: 24.7%;height: 50px;text-align: center; background-color: rgb(13,102,178,0.1); border:none; }
.button-blue span { color : #0D66B2; opacity: 1.0;}
.button-blue .anticon-right { padding-top: 3px; float: right; font-size: 15px; color: #0D66B2; font-weight: bold; }
.marginTop{margin-top: 33px;}
.marginTop-10{margin-top: 20px; text-align: left}
.marginTop-20{margin-top: 20px;}
.dashboard-subcontainer{margin-top: 1%; border-top-width: 5px;  border-radius: 4px;}
.left-heading{float: left;font-weight: 600;font-size: 16px;}
.right-heading{float: right;font-weight: 600;font-size: 14px;cursor: pointer;}
.marginTop-30{margin-top: 30px;}
.card{width: 90%;border-radius: 4px;}

/* Upload */
.upload-container{padding: 10px 40px 40px 40px; }
.heading{margin-bottom: 10px;font-size: 20px;text-align: center;vertical-align: middle;color: #000000;font-weight: 200;}
.booking-icon{font-size: 35px;}
.text-style{font-size: 12px;color: #000000;vertical-align: middle;}
.dropDown{width: 80%;}
.checkboxStyle{margin-top: 10px;}
.rectangle {height: 20px; width: 90%;}
.selected-rectangle {height: 20px; width: 90%;}
.rectangle .ant-select-selection {
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border-radius: 2px;
    -ms-user-select: none;
    border: none;
    user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #f2f2f2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selected-rectangle .ant-select-selection {
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border-radius: 2px;
    -ms-user-select: none;
    border:  2px solid #A43F9C!important;
    user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #f2f2f2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rectangle .ant-select-open .ant-select-selection {
    border-color:  2px solid #A43F9C!important;
    outline: 0;
    box-shadow: none!important;
    border-right-width: 1px !important;
}
.rectangle .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border: 2px solid #A43F9C;
    border-radius: 2px;
    outline: 0;
}
.rectangle .ant-select-open {
    border-color:  2px solid #A43F9C!important;
    outline: 0;
    box-shadow: none!important;
}
.rectangle .ant-select-selection {
    border-color:  2px solid #A43F9C!important;
    outline: 0;
    box-shadow: none!important;
}
.selected-rectangle .ant-select-selection {
    border-color:  2px solid #A43F9C!important;
    outline: 0;
    box-shadow: none!important;
}

.skip-this {	height: 11px;	color: #000000;	font-family: Roboto;	font-size: 12px;	font-weight: 500;    line-height: 22px;    margin-top: 10px;}
.continue-Button {	height: 35px;	width: 161px;    border-radius: 4px;   color: white;    margin: 1px 1px 1px -10px;	background-color: #0D66B2;}

/* DMS */
.DMS-container{padding: 10px 20px 10px 20px;}
.dms-heading{color: #000000;	font-family: Roboto;	font-size: 24px;	font-weight: 500;    line-height: 36px;    float: left;}
.amount-dropdown-view { height: 40px; width: 233px; border-radius: 20px; background-color:#e2e4e7; color: #000000; float: right;}
.amount-dropdown .ant-select-selection {    background-color: transparent;    width: 233px;    border: none;  }
.table-cell{border-right: none;border-left: none; text-align: center;}
.white-bg{background-color: #fff;}
.padding-vertical-10{padding: 10px 0 10px 0;}
.tableCell{border-right: none;border-left: none;}
.vendorSummary{background-color:#fbedeb !important; color:#E74C3C !important}
.button-downloade 
{   text-align: center;
    background-color: rgb(13,102,178,0.1); 
    border:none; 
    margin-top:25px;
    margin-right: 15px; 
    height: 40px;
}
.button-downloade span { color : rgb(20, 89, 150); opacity: 1.0;}
.button-downloade:hover { background-color: rgb(20, 89, 150); }
.button-downloade:hover span { color: #ffffff;}

.button-downloade-active { background-color: rgb(20, 89, 150); }
.button-downloade-active span { color: #ffffff; }


.button-downloade:focus { background-color: #0D66B2}
.button-downloade:focus span { background-color: rgb(20, 89, 150); color: #ffffff;}

.cloud_image{
    height:20px;
    width:25px;
    margin-right: 10px;
}

.drager_border{
    border-style: dashed;
    border-color: #5689c0;
    width: 100%;
    transition: border-color 0.3s;
    border-width: 2px;
}


.dragfile .ant-upload.ant-upload-drag {
    border-style: none !important;
    transition: border-color 0.1s;
    border-radius: 4px;
    position: relative;
    background: #fafafa;
    height: 300px;
    margin-top:70px;
}

.drager-line-view{
    width: 40%;
    margin-top:15px;
    margin-left: auto;
    margin-right: auto;
}

.dragger-line-view-other{
    margin-left: 33%;
    margin-top:40px;
}

.label{
    color:rgb(90, 147, 197)
}

.browse-button{
    width:150px;
    height: 40px;
    border-color: #0D66B2;
    color: #0D66B2;
    border-style: solid;
    border-bottom-width: 2px;
    margin-top: 15px;
    font-size: 18px;
}

.dragger-next-step{
    width: 100%;
    height: 370px;
    transition: border-color 0.3s;
    border-width: 2px;
    display: flex;
    flex-flow: row nowrap;
}

.center-div{
    display: flex;
    flex-flow: row nowrap;
}

.center {
    margin: auto;
}
.centerText {
    margin: auto;
    background-color: #FFFFFF;
}
.upload-file-name{
    height: 19px;		
    color: #000000;	
    font-family: Roboto;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 19px;	
    text-align: center;
}

.file-detail-text{
    height: 22px;		
    opacity: 0.5;	
    color: #000000;	
    font-family: Roboto;	
    font-size: 14px;	
    line-height: 22px;
    text-align: center;
}

.change-button{
    height: 40px;	
    width: 120px;	
    border: 1px solid #0D66B2;	
    color: #0D66B2;
    font-weight: 500;
    border-radius: 4px;
    background-color: transparent;
}
.countinue-button {
    height: 40px;	
    width: 120px;	
    margin-left: 10px;
    border-radius: 4px;	
    font-weight: 500;
    background-color: #0D66B2;
    color: #ffffff
}

.excel-image{
    width:150px;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.cancel-uploade-button{
    height: 40px;	
    width: 200px;	
    color:#0D66B2;
    border: 1px solid #0D66B2;	
    border-radius: 4px;
    margin-left:28px;
}

.button-holder{
    margin-top: 24px
}

.center-in-div{
    display: block;
    margin-left:auto;
    margin-right: auto;
}

.dragger_border{
    height: 600px;	
    width: 400px;	
    border-radius: 8px;	
    background-color: rgb(212, 231, 247);
    margin-right: 20px;
    border-style: solid;
    border-color: transparent;
    padding: 5px;
}

.draggerfile .ant-upload.ant-upload-drag {
    border-style: none !important;
    transition: border-color 0.1s;
    border-radius: 4px;
    position: relative;
    background: #fafafa;
    height: 90%;
    background-color: rgb(212, 231, 247);
}

.draggers-submit-button{
    height: 40px;	
    width: 140px;	
    border: 1px solid #0D66B2;	
    border-radius: 4px;
    margin-top:32px;
    font-family: Roboto;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 24px;	
    text-align: center;
}

.dragger-downloade-button{
    height: 64px;	
    width: 102%;
    opacity: 0.5;
    margin-left: -4px;	
    background-color: #9B59B6;
}

.uploade_file_image-other{
    width: 90px;
    height: 100px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.bottom-view{
    background-color: #0D66B2;
    width: 100%;
    height: 64px; 
}

.bottom-view-button{
    height: 40px;	
    width: 324px;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    float: right;
    margin-right: 15.33%;
    margin-top: 12px;
    color: #0D66B2;	
    font-family: Roboto;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 24px;	
    text-align: center;
}

.bottom-fix{
    position: fixed;
    bottom: 0;
    width: 100%;
}

.file-image{
    height: 96px;	
    width: 96px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

}

.padding{
    height: 100%;
    width: 100%
}

.padding:hover{
    border-style: dashed;
    border-color: #5689c0;
    transition: border-color 0.3s;
    border-width: 2px;
 }

.uploadJson-screen-left-portion { background-color: #F0F1F4}
.uploadJson-screen-sideBar-GSTR {
	width: 100%;
	border-radius: 4px;
	background-color: #DBE1EC;
}
.uploadJson-screen-sideBar-GSTR-row {
    height: 50%
}
.uploadJson-screen-sideBar-GSTR-label {
    color: #4C69Ab;
    font-size: medium;
    font-weight: 500;
    margin-top: 3%;
}
.uploadJson-screen-sideBar-button {
    color: #4C69Ab;
    border-color: #4C69Ab;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
    height: 36px;
    background-color: #DBE1EC;
}
.uploadJsonDropdown .ant-select-selection--single {
    height: 32px !important;
    position: relative !important;
    cursor: pointer !important;
    background-color: #D9E0EB !important;
}

.uploadJson-screen-sideBar-row {
    height: 55%;
    border-top-style: groove;
    border-top-width: 0.1px;
    border-top-color: #f7f7f71c;
}

.uploadJson-screen-sideBar-GSTR-violetDiv {	
        height: 48px;
        border-radius: 4px;
        background-color: #E5E2EC;
        margin-top: 2%
    }

.uploadJson-screen-sideBar-addFile-button {
    margin-top: 10px;
    color: #9B59B6;
    border-color: #9B59B6;
    font-weight: 500;
    background-color: #E5E2EC;
}
.uploadJson-screen-detail-watermark {
    position:absolute;
    z-index:0;
    background:white;
    display:block;
    min-height:50%; 
    min-width:50%;
    color:#D8D8D8;
}
.uploadJson-screen-detail-List {
    border-left-color: white;
}
.uploadJson-screen-detail-icon {height:70px;margin-left:10%;}
.uploadJson-screen-detailIcon {height:40px;margin-left:10%;}
.floatLeft{
    float: left;
}
.floatRight{
    float: right;
}
.uploadJson-screen-detail-List-row{
    padding: 12px; 
    width: 100%;
}
.uploadJson-screen-detail-List-row-label{
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.uploadJson-screen-detail-List-row-fileSize-label{
    margin-left: 7px;
     color: #ab9c9c 
}
.dms-tab { margin-left: 15px;}
.dms-footer {	height: 78px;	width: 100%;	background-color: #0D66B2;    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);   margin-top: 10px;    padding: 0px 120px ; }
.right { float: right; }
.upadte-cell-footer {	background-color: #0D66B2;    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);    padding: 40px 0px 10px 0px;}
.footer-label {	color: #FFFFFF;	font-family: Roboto;	font-size: 14px;	font-weight: 500;	line-height: 16px;}
.element-bg {	border: 1px solid #D9D9D9;    border-radius: 4px;    width: 90%;	background-color: #FFFFFF;}.white-text{color: #fff;}
.footer-padding{padding: 0px 100px 0px 100px;}
.footer-divider {	height: 1px;	width: 110%;    opacity: 0.2;    margin: 20px 0px 0px -125px;	background-color: #E3E3E3;}
.datepicker{height: 70px;}

/* Gst-Input */
.horizontal-center{text-align: center;}
.margin-horizontal-10{margin: 0px 10px 0px 10px;}
.gst-h1{margin-top: 104px; font-size: 24px;text-align: center;color: #000000;font-weight: 500;}
.welcome-to-gs-tinput {	height: 24px;	width: 317px;	opacity: 0.5;	color: #000000;	font-family: Roboto;	font-size: 16px;	line-height: 24px;	text-align: center;}
.gst-text1 {	opacity: 0.35;	color: #000000;	font-size: 12px;	font-weight: 500;    line-height: 18px;}
.padding-24{padding: 24px;}
.gst-btn {	height: 40px;	border: 1px solid #0D66B2;    border-radius: 4px;    width: 100%;    color: #0D66B2;    font-weight: 500;    font-size: 16px;    line-height: 24px;}
.gst-box {	box-sizing: border-box;    border: 1px solid #0D66B2;    border-radius: 4px;    margin: auto;    width: 100%;    padding: 13px 46px 13px 46px;}
.gst-box:hover{border: 1px solid #9B59B6; border-radius: 4px;}
.gst-btn-container{flex-flow: row nowrap; width: 100%; padding-left: 21%;}
.gst-text2 {    margin: auto;    font-size: 14px;    color: #0D66B2;	font-weight: 500;    line-height: 22px;}
.gst-text2:hover{color: #9B59B6;font-weight: 500;}
.pending-text{color: #E74C3C;font-size: 12px;font-weight: 500;line-height: 18px;}


/* login */
.center-flex {width: 100%;display: flex;justify-content: center;align-items: center;}
.login-container{padding: 0px 6% 0px 6%; }
.slider-content { width : 45%; min-height : 500px;}
.slick-slide { height : 450px !important;}
.login-logo { height: 22% ;width: 100%;padding: 5px 0px 5px 0px;}
.login-text1 { opacity: 0.5; color: #000000;font-size: 14px; text-align: center; vertical-align: middle; }



.white-card { float: right; background-color: #FFFFFF; width: 340px; border-radius: 8px;box-shadow: 0 2px 8px 0 rgba(0,0,0,0.05);padding: 10px 0px 25px 0px}

.img-card { margin: 20px 0px 20px 0px;	height: 130px; width: 135px; border-radius: 4px; background-color: #e4eff6; text-align: center; vertical-align: middle; cursor: pointer; }
.padding-Horozontal-24{ padding: 0px 24px 0px 24px; }
.logo-sm{ height: 58px;width: 58px;border-radius: 4px;box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);}
.requeest-otp-btn {	height: 40px;    width: 168px;    border: none;	border-radius: 4px;    background-color:#0D66B2 ;    margin: 24px 0px 24px 0px;    color:#ffffff}

.img-card-icon { height: 88.24px; width: 100.21px; margin-left: auto; margin-right: auto; }
.img-shape {
	height: 35px;
    width: 32px;
    margin: 20% auto 15% auto;
}
.img-card-text { font-size: 14px; line-height: 24px; text-align: center; font-weight: bold; color:#0D66B2;}

.Reg_text_h1 { color: #FFFFFF; font-family: "Roboto"; font-size: 25px; font-weight: bold; width: 65%; text-align: left; }
.Reg_text_h2 { color: #FFFFFF; font-family: "Roboto"; font-size: 20px; }
.see-features {	border-radius: 4px; background-color: #FFFFFF; color: #0D66B2; font-family: Roboto; font-size: 16px; font-weight: 500; cursor: pointer;}

.disabledbutton { pointer-events: none; opacity: 0.4; }
.disabledtext{ -webkit-text-decoration-line: line-through; text-decoration-line: line-through; }

.custom-dropdown .ant-select-selection--single {
    position: relative !important;
    cursor: pointer !important;
    border-style: none !important;
    border: 0px !important;
    background-color: transparent !important;
}
.register-here{
    vertical-align: middle;
    font-size: 14px;
    color: #0D66B2;
    line-height: 24px;
    cursor: pointer;
    font-weight:600 !important;
}

.report-prority{
    width: 180px;
    float: right;
    margin-right: 15px;
    background-color: #DDDD;
    border-radius: 20px;
}

.sort-by {		
    color: #000000;	
    font-family: Roboto;	
    font-size: 12px;	
    font-weight: 500;	
    text-align: center;
    margin-left: 10px;
    padding-left: 8px;
    padding-top: 20px
}
.antselection .ant-select-open .ant-select-selection{
    border-color: rgba(206, 203, 203, 0.867) !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    outline: 0 !important;
}
.register-btn {
	border: 1px solid #FFFFFF;
    border-radius: 4px;
    background-color: #1edaeb;
    color: #FFFFFF;
}


.file-img-size{ 
    height:40px; 
    width:40px; 
} 
 
.file-name-text-size{   
    color: #000000;   
    font-family: Roboto;   
    font-size: 12px;   
    font-weight: 500;   
    line-height: 18px; 
} 
 
.file-uploade-text{   
    color: green;   
    font-family: Roboto;   
    font-size: 12px;   
    font-weight: 500;   
    line-height: 18px; 
}

.documentTypeButton{
    border-color: #0D66B2;
    border-radius: 20px;
    margin-right: 3px;
    margin-left: 3px;
    color: #0D66B2;
    margin-top: 2px;
    margin-bottom: 2px;
}
.documentType_Image{ text-align: center; margin-top: 25px; margin-bottom: 10px }
.documentType_Text{ text-align: center; margin-bottom: 30px }
.documentType_Row{ margin-left: 30px; margin-right: 30px; }
.documentType_Rows{ margin-left: 30px; margin-right: 30px; margin-bottom: 30px; }
.document-popover .ant-popover-title {
    min-width: 177px!important;
    margin: 0!important;
    padding: 8px 16px 0px 16px!important;
    min-height: 32px!important;
    border-bottom: none!important;
    color: #989898!important;
    background-color: #333333!important;
    font-weight: 500!important;
}
.document-popover .ant-popover-inner-content {
    padding: 0px 16px 5px 16px !important;
    color: white !important;
   background-color: #333333 !important;
}
.document-popover .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
   background-color: #333333!important;
    -webkit-transform: translateX(-50%) rotate(45deg)!important;
    transform: translateX(-50%) rotate(45deg)!important;
}
.airline-summary .ant-table-tbody > tr > td {
    text-align: center;
    padding: 8px 5px 8px 5px !important;
    color: #000000;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-right: 1px solid rgb(0,0,0,0.1);
    border-bottom: 1px solid #D6D8DB;
}
.vendorGrid_columns{ float: right; padding: 5px; width: 100px; }
.vendorGrid_getColumns{ float: left; color: #0D66B2; }
.supplier_twoAGST{ float: left; margin-top: 4%; }
.supplier_twoAGST_label{ font-size: 10px; margin-left: 12%; }
.supplierTwoAGST{ margin-left: 12%; font-size: 10px; color: #AEC067; }
.supplierTwoAGSTColor{ margin-left: 12%; font-size: 10px; color: #758AB2; }
.supplier_booksGST{ margin-left: 12%; font-size: 10px; color: #9075AA; }
.supplier_differenceInGST{ margin-left: 12%; font-size: 10px; color: #CA927F; }

.search-reporting{ margin-top: 20px; margin-left: 20px; }
.search-reporting-row{ margin-top: 25px; margin-left: 20px; margin-right: 20px; margin-bottom: 15px; }
.search-reporting-list{ margin-left: 20px; margin-right: 20px; }

.uploadJson_sidebar{ float: left; margin-left: 13px; margin-top: 5px; }
.uploadJson_sidebar_icon{ margin-left: 15px; margin-top: 5px; font-size: 12px; }
.uploadJson_sidebar_col{ float: right; margin-right: 12px; }
.uploadJson_sidebar_color{ color: #9B59B6; margin-top: 10px; }

.uploadJson-details-icon{ font-size: 15px; margin-right: 8px; }
.uploadJson-details-loader{ font-size: 20px; margin-right: 8px; }

.comment-arrow{ float: right; margin-top: -20px; margin-right: 15px; position: relative; }

.upload-new-report-center {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
}

.main--header h2 {
    font-size: 28px;
    color: #fff;
}

.main--header {
    padding: 90px 10px;
    color: #fff;
}

.main--header p {
    font-size: 18px;
}

.main--footer-bottom {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 100px;
}

.main--footer-bottom a.btn.learnMore-btn {
    background: #6558f5;
    color: #fff;
}

.main--footer-bottom p a {
    color: #6558f5;
    margin-left: 10px;
    font-size: 16px;
    border-bottom:1px solid #6558f5;
}

.company-flex {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin: auto;
    justify-content: center;
}

.company-flex img {
    max-width: 185px;
    display: block;
}

.main--footer{
    padding-top: 40px;
}
.main--footer-top h2 {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
}


.ant-modal-footer > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.ant-modal-footer  button {
    border-radius: 20px;
    min-width: 90px;
    margin: 0;
}

.ant-modal-footer {
    border: none;
    padding-top: 0;
}

.ant-modal-header {
    border: none;
    padding-bottom: 0;
}

.ant-modal-body iframe{
    width:100%;
    height: 100%;
    margin-top: 16px;
}

.ant-modal
{
    width: 750px !important;
    -webkit-transform-origin: 796.5px 330px;
            transform-origin: 796.5px 330px;
}

@media (min-width: 768px)  {

    .company-flex img:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .company-flex img:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    
.white-card-main{
    position: absolute;
    width: 100%;
     right: 8.33%;
}

    
    
}

@media (max-width: 767px)  {
    
    .company-flex {
        flex-wrap: wrap;
    }

    .main--header h2 {
        font-size: 20px;
        color: #fff;
    }

    .main--header p {
        font-size: 14px;
    }

    .login-container.center-flex{
        display: block;
    }
    .white-card{
        float: none;
        width: 100%;
    }

    .white-card-main {
        display: inline-block;
        width: 100%;
    }

    .main--header {
        padding: 40px 15px;
    }

    .slider-content {
        width: 100%;
    }

    .main--footer-bottom {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        padding-right: 88px;
    }
    a.btn.learnMore-btn {
        margin: 0;
        font-size: 14px !important;
    }

    .main--footer-bottom p a {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
    }

    .main--footer-bottom p a:first-child{
        margin: 0;
    }


    
}






/* Mostly typography, shift to typography.js with theme */

body {
	width: 100%;
	height: 100vh;
	font-family: Proxima Nova;
}

h1 {
	font-size: 1.6em;
	font-weight: 500;
}

h2 {
	font-size: 1.4em;
	font-weight: 500;
}

h3 {
	font-weight: 500;
	font-size: 1em;
}

h4 {
	font-weight: 500;
}

.iframe-container {
	position: absolute;
	width: calc(100% - 40px);
	height: 100%;
}

.report-anchor-container {
	margin-bottom: 5px;
}

.report-anchor {
	color: #007bff !important;
	cursor: pointer;
}

.report-anchor:focused {
	text-decoration: underline !important;
}

.result-powered-by {
	display: none !important;
}

/*
select {
	width: 180px;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}*/

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}

.Select {
	margin: 8px 0;
}

.Select-control {
	height: 41px;
}

.Select-value-label {
	line-height: 38px;
}

.s-alert-close {
	display: none;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.12ccd551.woff")
		format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Light';
	src: url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.17aa4534.woff")
		format('woff');
	font-weight: normal;
	font-style: normal;
}

.formSelector {
	margin: -11px;
	margin-left: -18px;
	height: 21px !important;
	margin-top: -9px;
}

body {
	font-family: 'Open Sans', sans-serif;
	line-height: 1.25;
}

table {
	border: 1px solid #ccc;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
}

table caption {
	font-size: 1.5em;
	margin: 0.5em 0 0.75em;
}

table thead tr {
	background: #f8f8f8;
}

table tr {
	border: 1px solid #ddd;
	padding: 0.35em;
}

table th,
table td {
	padding: 0.625em;
	text-overflow: ellipsis;
	text-align: left;
}

.table-cell {
	padding: 7px 5px;
}

.table-cell:hover {
	background: green;
	cursor: pointer;
}

.btn-add-right {
	float: right;
	margin-bottom: 18px;
	color: #fff !important;
	width: 80px;
	padding: 0.6em;
	border: none;
	background-color: #58ade5;
	margin: 1em 0;
	border-radius: 2px;
	cursor: pointer;
	text-align: center;
}

.btn-edit {
	float: right;
	color: #fff !important;
	width: 80px;
	padding: 0.6em;
	border: none;
	background-color: #58ade5;
	border-radius: 2px;
	cursor: pointer;
	text-align: center;
}

.inputText {
	width: 90%;
	padding: 10px 10px;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	font-family: Proxima Nova;
}

.col,
.half,
.third,
.twothird,
.threequarter,
.quarter {
	float: left;
	width: 100%;
}

.col.s1 {
	width: 8.33333%;
}

.col.s2 {
	width: 16.66666%;
}

.col.s3 {
	width: 24.99999%;
}

.col.s4 {
	width: 33.33333%;
}

.col.s5 {
	width: 41.66666%;
}

.col.s6 {
	width: 49.99999%;
}

.col.s7 {
	width: 58.33333%;
}

.col.s8 {
	width: 66.66666%;
}

.col.s9 {
	width: 74.99999%;
}

.col.s10 {
	width: 83.33333%;
}

.col.s11 {
	width: 91.66666%;
}

.col.s12 {
	width: 99.99999%;
}

@media (min-width: 601px) {
	.col.m1 {
		width: 8.33333%;
	}
	.col.m2 {
		width: 16.66666%;
	}
	.col.m3,
	.quarter {
		width: 24.99999%;
	}
	.col.m4,
	.third {
		width: 33.33333%;
	}
	.col.m5 {
		width: 41.66666%;
	}
	.col.m6,
	.half {
		width: 49.99999%;
	}
	.col.m7 {
		width: 58.33333%;
	}
	.col.m8,
	.twothird {
		width: 66.66666%;
	}
	.col.m9,
	.threequarter {
		width: 74.99999%;
	}
	.col.m10 {
		width: 83.33333%;
	}
	.col.m11 {
		width: 91.66666%;
	}
	.col.m12 {
		width: 99.99999%;
	}
}

@media (min-width: 993px) {
	.col.l1 {
		width: 8.33333%;
	}
	.col.l2 {
		width: 16.66666%;
	}
	.col.l3 {
		width: 24.99999%;
	}
	.col.l4 {
		width: 33.33333%;
	}
	.col.l5 {
		width: 41.66666%;
	}
	.col.l6 {
		width: 49.99999%;
	}
	.col.l7 {
		width: 58.33333%;
	}
	.col.l8 {
		width: 66.66666%;
	}
	.col.l9 {
		width: 74.99999%;
	}
	.col.l10 {
		width: 83.33333%;
	}
	.col.l11 {
		width: 91.66666%;
	}
	.col.l12 {
		width: 99.99999%;
	}
}

@media screen and (max-width: 600px) {
	table {
		border: 0;
	}
	table caption {
		font-size: 1.3em;
	}
	table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	table tr {
		border-bottom: 3px solid #ddd;
		display: block;
		margin-bottom: 0.625em;
	}
	table td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: 0.8em;
		text-align: right;
	}
	table td:before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}
	table td:last-child {
		border-bottom: 0;
	}
}

/*.btn, .button {
	border: none;
	display: inline-block;
	outline: 0;
	padding: 8px 16px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	color: inherit;
	background-color: inherit;
	text-align: center;
	cursor: pointer;
	white-space: nowrap
}

.btn:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.btn, .button {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}*/

.disabled,
.btn:disabled,
.button:disabled {
	cursor: not-allowed;
	opacity: 0.3;
}

.disabled *,
:disabled * {
	pointer-events: none;
}

.btn.disabled:hover,
.btn:disabled:hover {
	box-shadow: none;
}

/* Colors */

.amber,
.hover-amber:hover {
	color: #000 !important;
	background-color: #ffc107 !important;
}

.aqua,
.hover-aqua:hover {
	color: #000 !important;
	background-color: #00ffff !important;
}

.blue,
.hover-blue:hover {
	color: #fff !important;
	background-color: #2196f3 !important;
}

.light-blue,
.hover-light-blue:hover {
	color: #000 !important;
	background-color: #87ceeb !important;
}

.brown,
.hover-brown:hover {
	color: #fff !important;
	background-color: #795548 !important;
}

.cyan,
.hover-cyan:hover {
	color: #000 !important;
	background-color: #00bcd4 !important;
}

.blue-grey,
.hover-blue-grey:hover,
.blue-gray,
.hover-blue-gray:hover {
	color: #fff !important;
	background-color: #607d8b !important;
}

.green,
.hover-green:hover {
	color: #fff !important;
	background-color: #4caf50 !important;
}

.light-green,
.hover-light-green:hover {
	color: #000 !important;
	background-color: #8bc34a !important;
}

.indigo,
.hover-indigo:hover {
	color: #fff !important;
	background-color: #3f51b5 !important;
}

.khaki,
.hover-khaki:hover {
	color: #000 !important;
	background-color: #f0e68c !important;
}

.lime,
.hover-lime:hover {
	color: #000 !important;
	background-color: #cddc39 !important;
}

.orange,
.hover-orange:hover {
	color: #000 !important;
	background-color: #ff9800 !important;
}

.deep-orange,
.hover-deep-orange:hover {
	color: #fff !important;
	background-color: #ff5722 !important;
}

.pink,
.hover-pink:hover {
	color: #fff !important;
	background-color: #e91e63 !important;
}

.purple,
.hover-purple:hover {
	color: #fff !important;
	background-color: #9c27b0 !important;
}

.deep-purple,
.hover-deep-purple:hover {
	color: #fff !important;
	background-color: #673ab7 !important;
}

.red,
.hover-red:hover {
	color: #fff !important;
	background-color: #f44336 !important;
}

.sand,
.hover-sand:hover {
	color: #000 !important;
	background-color: #fdf5e6 !important;
}

.teal,
.hover-teal:hover {
	color: #fff !important;
	background-color: #009688 !important;
}

.yellow,
.hover-yellow:hover {
	color: #000 !important;
	background-color: #ffeb3b !important;
}

.white,
.hover-white:hover {
	color: #000 !important;
	background-color: #fff !important;
}

.black,
.hover-black:hover {
	color: #fff !important;
	background-color: #000 !important;
}

.grey,
.hover-grey:hover,
.gray,
.hover-gray:hover {
	color: #000 !important;
	background-color: #bbb !important;
}

.light-grey,
.hover-light-grey:hover,
.light-gray,
.hover-light-gray:hover {
	color: #000 !important;
	background-color: #f1f1f1 !important;
}

.dark-grey,
.hover-dark-grey:hover,
.dark-gray,
.hover-dark-gray:hover {
	color: #fff !important;
	background-color: #616161 !important;
}

.pale-red,
.hover-pale-red:hover {
	color: #000 !important;
	background-color: #ffdddd !important;
}

.pale-green,
.hover-pale-green:hover {
	color: #000 !important;
	background-color: #ddffdd !important;
}

.pale-yellow,
.hover-pale-yellow:hover {
	color: #000 !important;
	background-color: #ffffcc !important;
}

.pale-blue,
.hover-pale-blue:hover {
	color: #000 !important;
	background-color: #ddffff !important;
}

.text-red,
.hover-text-red:hover {
	color: #f44336 !important;
}

.text-green,
.hover-text-green:hover {
	color: #4caf50 !important;
}

.text-blue,
.hover-text-blue:hover {
	color: #2196f3 !important;
}

.text-yellow,
.hover-text-yellow:hover {
	color: #ffeb3b !important;
}

.text-white,
.hover-text-white:hover {
	color: #fff !important;
}

.text-black,
.hover-text-black:hover {
	color: #000 !important;
}

.text-grey,
.hover-text-grey:hover,
.text-gray,
.hover-text-gray:hover {
	color: #757575 !important;
}

.text-amber {
	color: #ffc107 !important;
}

.text-aqua {
	color: #00ffff !important;
}

.text-light-blue {
	color: #87ceeb !important;
}

.text-brown {
	color: #795548 !important;
}

.text-cyan {
	color: #00bcd4 !important;
}

.text-blue-grey,
.text-blue-gray {
	color: #607d8b !important;
}

.text-light-green {
	color: #8bc34a !important;
}

.text-indigo {
	color: #3f51b5 !important;
}

.text-khaki {
	color: #b4aa50 !important;
}

.text-lime {
	color: #cddc39 !important;
}

.text-orange {
	color: #ff9800 !important;
}

.text-deep-orange {
	color: #ff5722 !important;
}

.text-pink {
	color: #e91e63 !important;
}

.text-purple {
	color: #9c27b0 !important;
}

.text-deep-purple {
	color: #673ab7 !important;
}

.text-sand {
	color: #fdf5e6 !important;
}

.text-teal {
	color: #009688 !important;
}

.text-light-grey,
.hover-text-light-grey:hover,
.text-light-gray,
.hover-text-light-gray:hover {
	color: #f1f1f1 !important;
}

.text-dark-grey,
.hover-text-dark-grey:hover,
.text-dark-gray,
.hover-text-dark-gray:hover {
	color: #3a3a3a !important;
}

.border-red,
.hover-border-red:hover {
	border-color: #f44336 !important;
}

.border-green,
.hover-border-green:hover {
	border-color: #4caf50 !important;
}

.border-blue,
.hover-border-blue:hover {
	border-color: #2196f3 !important;
}

.border-yellow,
.hover-border-yellow:hover {
	border-color: #ffeb3b !important;
}

.border-white,
.hover-border-white:hover {
	border-color: #fff !important;
}

.border-black,
.hover-border-black:hover {
	border-color: #000 !important;
}

.border-grey,
.hover-border-grey:hover,
.border-gray,
.hover-border-gray:hover {
	border-color: #bbb !important;
}

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-50 {
	margin-top: 50px;
}

.mb-50 {
	margin-bottom: 50px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-60 {
	margin-left: 60px;
}

.width-90 {
	width: 90% !important;
}

.width-45 {
	width: 45% !important;
}

.padder {
	padding: 20px;
}

.order-wrapper {
	margin-left: 22px;
	margin-right: 42px;
}

.btn-red {
	width: 152px;
	border: 2px solid;
	border-radius: 7px;
	height: 23px;
	padding: 5px;
}

.btn-upload {
	width: 92px;
	border: 1px solid;
	border-color: rgba(187, 187, 187, 0.79) !important;
	background-color: rgba(229, 229, 229, 0.75);
	border-radius: 7px;
	padding: 5px;
	margin-top: -5px;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.link {
	color: #6fb9c1;
	font-size: 12px;
}

.link:hover {
	color: #17d4e8;
	font-size: 12px;
}

.content {
	color: #000 !important;
	background-color: #f9f9f9 !important;
}

.btn-save {
	border: 1px solid;
	width: 45px;
	border-radius: 2px;
	margin-top: 80%;
	margin-bottom: -20px;
	height: 16px;
	padding: 12px;
	color: white;
	background-color: #d64830;
}

.menuTop {
    background: #2d373c;
    color: white;
    position: fixed;
    width: 68px;
    left: 0px;
    top: 0px;
    height: 44px;
    z-index: 1000;
}

.headerTop {
	background: #2d373c;
	color: white;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.headerTop .avatar {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	-webkit-user-select: auto;
	        user-select: auto;
	height: 50px;
	width: 50px;
}

.headerTopWithoutColor {
	color: white;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.headerTopWithoutColor .avatar {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	-webkit-user-select: auto;
	        user-select: auto;
	height: auto;
	width: auto;
}

.dropdown-holder {
	padding-top: 12px;
}

.top-dropdown {
	position: absolute;
	left:50px;
	top: 16px !important;
	border-radius: inherit;
	-webkit-border-radius: inherit;
}

.top-dropdown a {
	color: #212529;
}

.dropdown-btn,
.dropdown-btn:active,
dropdown-bt:hover {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	color: #fff !important;
	padding: 0.5rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.25 !important;
}

.app-notification {
	margin-right: 2rem;
}

.app-notification-icon a,
.app-notification a:active,
.app-notification a:visited {
	color: white;
}

.app-notification-icon {
	margin-left: 2.1rem;
	position: relative;
}

.app-notification-icon svg {
	height: 1.3rem;
	width: 1.3rem;
}

.notification-text h5 {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
}

.notification-avatar {
	width: 50px;
}

.notification-avatar img {
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
}

.notification-counter {
	position: absolute;
	top: 0;
	right: 1px;
}

.brand {
	margin-left: 10px;
	display: flex;
}

.brand a {
	padding: 10px 0 10px 0;
}

.brand img {
	height: 50px;
	width: 210px;
	object-fit: contain;
}

.brand-contact img {
	max-width: 180px;
}

.address {
	line-height: 1.5rem;
}

.nav-holder {
}

.all-container {
	padding: 90px 20px 0 20px;
}

.account-dl dt {
	font-weight: 600;
	line-height: 2rem;
}

.account-dl dd {
	min-height: 20px;
}

.avatar-holder img {
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
}

/* modal override */

.narcissus_17w311v {
	padding: 0 !important;
}

.narcissus_17w311v .narcissus_86uon7 {
	right: 3px;
	top: 7px;
	left: auto;
}

.narcissus_86uon7 circle {
	fill: none;
}

.narcissus_86uon7 rect {
	fill: black;
}

.select-custom {
	margin: 0;
	min-width: 75px;
}

.btn {
	cursor: pointer !important;
	display: inline-block !important;
	font-weight: normal !important;
	text-align: center !important;
	white-space: nowrap !important;
	vertical-align: middle !important;
	-webkit-user-select: none !important;
	user-select: none !important;
	border: 1px solid transparent !important;
	padding: 0.5rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.25 !important;
	border-radius: 0.25rem !important;
	transition: all 0.15s ease-in-out !important;
}

dl.d-flex dd {
	margin-left: 2rem;
}

.btn-icon-holder {
	margin-left: 1rem;
}

.btn-icon {
	padding: 0;
	cursor: pointer;
}

.btn-icon svg {
	vertical-align: top;
	height: 20px;
	width: 20px;
	margin-top: -3px;
}

.react-datepicker__close-icon {
	display: inline;
}

.App {
	text-align: center;
	min-height: 560px !important;
}

.dropzone .App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.dropzone .App-title {
	font-size: 1.5em;
}

.dropzone .App-intro {
	font-size: large;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.dropzone .form-control {
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
}

.leftColumn {
	width: 12%;
	float: left;
	height: 54rem;
	background-color: #f2f2f2;
}

.rightColumn {
	width: 88%;
	float: right;
	background-color: #ffffff;
}

.airlines-list {
	text-align: left;
	padding-left: 0;
	margin-top: 2%;
	border-top: 0.1px solid #c7c7c7;
	overflow-y: auto;
}

.listStyleCollapse {
	padding-bottom: 3%;
	padding-top: 4.5%;
	padding-left: 5%;
	color: #828282;
	font-family: Lato;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 5px;
}

.staticAirlines-list {
	text-align: left;
	padding-left: 0;
	margin-top: 2%;
	border-top: 0.1px solid #c7c7c7;
	overflow-y: auto;
}

ul.airlines-list li.listStyleCollapse {
	position: relative;
}

ul.airlines-list li.listStyleCollapse:after {
	content: '';
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #817676;
	top: 33%;
}

.custom-pagination {
	position: fixed;
	right: 50px;
	z-index: 105;
}

.custom-pagination .active {
	background-color: #2d373c !important;
	color: #fff;
}

.color-yellow-opac-35 {
	background-color: rgba(255, 175, 0, 0.35);
	color: #000;
}
.color-green-opac-35 {
	background-color: rgba(26, 188, 156, 0.35);
}
.color-blue-opac-35 {
	background-color: rgba(13, 102, 178, 0.35);
}

.virtualized_id_col {
	z-index: 101 !important;
	outline: 0;
	overflow: hidden !important;
}

.css-1py3auy {
	padding: 90px 20px 0 20px;
}

.Loader {
	padding: 90px 20px 0 20px;
}

.track-horizontal {
	position: absolute;
	height: 6px;
	right: 2px;
	bottom: 2px;
	left: 2px;
	border-radius: 3px;
}
.track-horizontal .thumb-horizontal {
	position: relative;
	display: block;
	height: 100%;
	cursor: pointer;
	border-radius: inherit;
	background-color: rgba(125, 149, 255, 0.993);
}

.track-view {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	overflow: scroll;
	margin-right: -15px;
	margin-bottom: -17px !important;
}

/* Arrow & Hover Animation */
#more-arrows {
	width: 75px;
	height: 65px;
  }
  #more-arrows:hover polygon {
	fill: #FFF;
	transition: all .2s ease-out;
  }
  #more-arrows:hover polygon.arrow-bottom {
	-webkit-transform: translateY(-18px);
			transform: translateY(-18px);
  }
  #more-arrows:hover polygon.arrow-top {
	-webkit-transform: translateY(18px);
			transform: translateY(18px);
  }
  
  polygon {
	fill: #FFF;
	transition: all .2s ease-out;
  }
  polygon.arrow-middle {
	opacity: 0.75;
  }
  polygon.arrow-top {
	opacity: 0.5;
  }
  
.dropzone ul.airlines-list {
      list-style-type: none;
      text-align: left;
      padding-left: 0;
      margin-top: 2%;
      border-top: 0.1px solid #c7c7c7;
      overflow-y: auto;
  }

.dropzone .listStyle {
      padding-bottom: 6%;
      padding-top: 7.5%;
      color: #828282;
      font-family: Lato;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
      border-bottom: 0.1px solid #c7c7c7;
  }

.dropzone .dropdownInnerStyle {
      height: 10rem;
  }

.dropzone .DropFile_span {
      font-family: Segoe;
      font-size: 30px;
      margin: 0;
  }

.dropzone .dropzone {
      padding: 10%;
  }

.dropzone .formArea {
      margin-top: 20px;
      margin-right: 4%;
      margin-left: 4%;
  }

.dropzone .submitDiv {
      height: 3rem;
      background-color: #3a97c9;
      width: 10%;
      right: 0;
      position: absolute;
      margin-right: 9%;
      color: #fff;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
  }

.dropzone .dropdown {
      margin-top: 2%;
      position: inherit;
      bottom: 0;
      margin-bottom: 5rem;
  }

.dropzone .selectDropClass {
      width: 25%;
      margin-left: 35%;
  }

.dropzone .submitDivDisable {
      height: 3rem;
      background-color: #8bc5e4;
      width: 10%;
      right: 0;
      position: absolute;
      margin-right: 9%;
      color: #fff;
      text-align: center;
      border-radius: 10px;
  }

.dropzone .Select-control {
      height: 45px;
  }

.dropzone .Select-placeholder {
      line-height: 45px;
  }

.dropzone .Select-input>input {
      line-height: 28px;
  }

.dropzone img {
      max-width: 100%;
  }

.dropzone .previewDiv {
      display: inline-block;
      vertical-align: top;
      width: 20%;
      text-align: center;
      margin: 25px auto 10px;
  }
.full-page-message {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-selection--multiple {
  display: none;
}


.heading {
  text-align: left;
  font-weight: 500;
}

.heading-sm {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}

.ant-select-dropdown-menu-item {
  border-top: 1px solid #ccc;
}

.dataTable {
  margin-top: 17px;
}

.bank-details {
  font-weight: 500;
  text-align: left;
}

.bank-details div {
  width: 40%;
  float: left;
  font-weight: 600;
}


.description{
  text-align: left;
  font-size: 16px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 20px;
}

.description strong {
  font-size: 20px;
  margin-bottom: 20px;
}

.ant-select-dropdown {
  z-index: 1;
}

